<template>
  <!-- error show -->
  <v-dialog v-if="!isCustom" v-model="dialogShow" max-width="600" width="90%" overlay-color="white">
    <v-card class="dialog-content d-flex flex-column justify-center align-center pa-md-10 pa-5">
      <div class="red--text rem-13">{{ $t(title) }}</div>
      <div class="rem-13 mb-5">{{ $t(content) }}</div>
      <btn :buttonText="'confirm'" :width="270" @clickBtn="dialogShow = false"></btn>
    </v-card>
  </v-dialog>
  <!-- reset show -->
  <v-dialog v-else v-model="resetShow" max-width="600" width="90%" @click:outside="$emit('update:resetShow', false)">
    <v-card class="dialog-content" :class="{'pa-md-10 pa-5':!noPadding}">
      <slot name="custom"></slot>
    </v-card>
  </v-dialog>
</template>
<script>
import btn from '@/components/btn.vue'
export default {
  name: 'dialogs',
  props:{
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    isCustom: {
      type: Boolean,
      default: false
    },
    resetShow: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      isConfirm: false,
      dialogShow: false
    }
  },
  watch: {
    "$store.state.dialogShow": {
      handler: function(newVal){
        this.dialogShow = newVal
      }
    },
    dialogShow(newVal){
      this.$store.commit('updateDialogShow', newVal)
    }
  },
  components:{
    btn,
  },
  methods:{
    confirm(){
      if (this.isConfirm){
        this.$emit('confirm')
      }else{
        this.$toasted.error(this.$t('agreeFirst'))
      }
    },
  },
}
</script>
<style lang="scss">
.v-dialog{
  border: 1px solid #ABABAB !important;
  border-radius: 40px !important;
  background-color: #EFEBEB;
}
.dialog-content{
  background-color: #EFEBEB !important;
}
</style>