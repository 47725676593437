<template>
  <div class="bonus-index d-flex justify-center">
    <div class="card-wrap d-flex flex-column align-center w-full">
      <pageTitle icon="icon-reward-red" text="bonus"></pageTitle>
      <div class="rem-md-13 rem-8">{{ $t('myVault') }}</div>
      <div class="d-flex align-center red--text rem-md-13 rem-8 mb-5">
        <span class="mr-3">{{ $store.state.userInfo.reward }} {{ $t('bonusAmount') }}</span>
        <btn :buttonText="'withdrawNow'" @clickBtn="withdrawShow=true"></btn>
      </div>

      <div class="d-flex jusfity-space-between mb-2">
        <!--<inputBlock mode="date" :inputText.sync="date" range placeholder="請選擇日期"></inputBlock>-->
        <inputBlock mode="select" :selectItems="[{name: $t('directRefer'), value: 1}, {name: $t('algebra'), value: 2}]" :inputText.sync="type" :rules="[required]"></inputBlock>
      </div>

      <v-data-table
        class="bonus-history"
        :headers="headers"
        :items="items"
        item-class="items-row"
        hide-default-footer
        :items-per-page="-1"
      >
        <!--header-->
        <template v-slot:header.amount="{}">
          <div>{{ type === 1 ? `${$t('directReferReward')}(TBT)` : `${$t('algebraReward')}(TBT)` }}</div>
        </template>
        <!--item-->
        <template v-slot:item.time="{item}">
          <div>{{ timestampToDate(item.time * 1000) }}</div>
        </template>
        <template v-slot:item.member="{item}">
          <div>0{{ item.member }}</div>
        </template>
      </v-data-table>
    </div>
    <dialogs :resetShow.sync="withdrawShow" isCustom noPadding>
      <template slot="custom">
        <v-form ref="form" class="d-flex flex-column align-center w-full pt-5" lazy-validation>
          <div class="darkGrey--text rem-10 mb-4">{{ $t('withdrawNowTitle') }}</div>
          <inputBlock class="mb-2 w-full" :placeholder="$t('enterWithdrawAmount')" :inputText.sync="withdrawAmount" :rules="[required, ...TokenAmountRules]"></inputBlock>
          <div class="orange--text text-center mb-3">
            <div class="rem-6 my-2">{{ $t('bonusWithdrawRate') }}</div>
            <div v-for="(p, i) in $t('withdrawTips')" :key="i">{{ p }}</div>
          </div>
        </v-form>
        <div>
          <div class="withdraw-btn py-5 text-center can-click" @click="send()">{{ $t('confirm') }}</div>
          <div class="withdraw-btn py-5 text-center can-click" @click="withdrawShow=false">{{ $t('cancel') }}</div>
        </div>
      </template>
    </dialogs>
  </div>
</template>
<script>
import base from '@/mixin/base'
import pageTitle from '@/components/pageTitle.vue'
import inputBlock from '@/components/inputBlock.vue'
import dialogs from '@/components/admin/dialogs.vue'
import btn from '@/components/admin/btn.vue'
export default {
  name: "Bonus",
  mixins: [base],
  data(){
    return {
      headers: [
        { text: this.$t('date'), value: 'time', sortable: false, align: 'center', class: 'red white--text' },
        { text: `${this.$t('referer')}`, value: 'member', sortable: false, align: 'center', class: 'red white--text' },
        { text: `${this.$t('directReferReward')}(TBT)`, value: 'amount', sortable: false, align: 'center', class: 'red white--text' },
        // { text: this.$t('toDetail'), value: 'action', sortable: false, align: 'center', class: 'red white--text' },
      ],
      items: [],
      type: 1,
      date: [],
      withdrawShow: false,
      withdrawAmount: null
    }
  },
  components:{
    pageTitle,
    inputBlock,
    dialogs,
    btn
  },
  watch: {
    type: {
      handler: async function(){
        this.headers[2].text = this.type === 1 ? `${this.$t('directReferReward')}(TBT)` : `${this.$t('algebraReward')}(TBT)`
        await this.getRewardList()
      }
    },
    withdrawShow(){
      this.$refs.form.reset()
    }
  },
  methods:{
    async getRewardList(){
      try {
        let result = await this.$store.dispatch('referHistory', this.type === 1 ? 'straight' : 'algebra')
        console.log('result', result)
        if (result.status === 200){
          this.items = result.data ? result.data : []
        }else{
          this.items = []
          this.$toasted.error(this.$t('cannotGetData'))
        }
      }catch(error){
        this.items = []
        console.log('error', error)
      }
    },
    async send(){
      if (this.$refs.form.validate()){
        try {
          let result = await this.$store.dispatch('withdrawBonus', {amount: parseInt(this.withdrawAmount)})
          console.log('result', result)
          if (result.status === 200){
            this.$refs.form.reset()
            this.withdrawShow = false
            this.$toasted.show(this.$t('withdrawApplySuccess'))
          }else{
            this.$toasted.error(this.$t('withdrawApplyFail'))
          }
        }catch(error){
          this.$toasted.error(this.$t('withdrawApplyFail'))
          console.log('error', error)
        }
      }
    }
  },
  async mounted(){
    await this.getRewardList()
  }
}
</script>
<style lang="scss">
.bonus-index{
  .bonus-history{
    width: 90%;
    tr:nth-child(2n){
      background-color: #EF9A9A;
    }
  }
}

.withdraw-btn{
  width: 50%;
  display: inline-block;
  border-top: 1px solid #ABABAB;
}
.withdraw-btn:first-child{
  border-right: 1px solid #ABABAB;
}
</style>